<template>
  <v-data-table
    :headers="headers"
    :items="reports"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:item.date="{ item }">
      {{ item.can_date_time ? item.can_date_time.substring(0, 10) : '-' }}
    </template>
    <template v-slot:item.time="{ item }">
      {{ item.can_date_time ? item.can_date_time.substring(10, 19) : '-' }}
    </template>
    <template v-slot:item.status="{ item }">
      <div  v-if="!item.date_pay">
         <span style="color:#3c763d;" v-if="item.complete_status === 'Y'">(ชำระครบจำนวนแล้ว)</span>
         <span style="color:rgb(222, 66, 62);"
               v-if="item.complete_status === 'N'">(ค้างชำระ)</span>
         <span style="color:rgb(222, 66, 62);;"
               v-if="item.complete_status === 'C'">(ยกเลิก)</span>

      </div>
      <div  v-if="item.date_pay">
         <span style="color:#3c763d;" v-if="item.confirm_pay === 'Y'">(ชำระครบจำนวนแล้ว)</span>
         <span style="color:rgb(222,147,7);"
               v-if="item.confirm_pay === 'W'">(รอการตรวจสอบ)</span>
         <span style="color:rgb(222, 66, 62);;"
               v-if="item.confirm_pay === 'C'">(ยกเลิก)</span>
      </div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance_report from '@/services/report'
import instance_user from '@/services/userManage'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    reports: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      { text: 'เลขอ้างอิง', value: 'mem_package_no' , sortable: false},
      { text: 'ประเภทแพ็กเกจ', value: 'product_type.product_type_name', sortable: false },
      { text: 'ประเภทการจ่าย', value: 'payment_type.name', sortable: false },
      { text: 'วันที่', value: 'date' , sortable: false},
      { text: 'เวลา', value: 'time' , sortable: false},
      { text: 'จำนวนเครดิต(ชิ้น)', value: 'amount' , sortable: false},
      { text: 'จำนวนเงิน(บาท)', value: 'total_price', sortable: false },
      { text: 'หมายเหตุ', value: 'confirm_pay_detail' , sortable: false},
      { text: 'สถานะ', value: 'status', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
    defaultItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
  }),


  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.getReport()
  },

  methods: {

    editItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    getReport() {
      this.$store.state.isLoading = true
      var data = {
        search : '',
        date : '',
        status : '',
      }

      instance_user.getReportMemberPackage(data).then(res => {
        this.reports = res.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
`
