var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reports,"search":_vm.search,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.can_date_time ? item.can_date_time.substring(0, 10) : '-')+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.can_date_time ? item.can_date_time.substring(10, 19) : '-')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!item.date_pay)?_c('div',[(item.complete_status === 'Y')?_c('span',{staticStyle:{"color":"#3c763d"}},[_vm._v("(ชำระครบจำนวนแล้ว)")]):_vm._e(),(item.complete_status === 'N')?_c('span',{staticStyle:{"color":"rgb(222, 66, 62)"}},[_vm._v("(ค้างชำระ)")]):_vm._e(),(item.complete_status === 'C')?_c('span',{staticStyle:{"color":"rgb(222, 66, 62)"}},[_vm._v("(ยกเลิก)")]):_vm._e()]):_vm._e(),(item.date_pay)?_c('div',[(item.confirm_pay === 'Y')?_c('span',{staticStyle:{"color":"#3c763d"}},[_vm._v("(ชำระครบจำนวนแล้ว)")]):_vm._e(),(item.confirm_pay === 'W')?_c('span',{staticStyle:{"color":"rgb(222,147,7)"}},[_vm._v("(รอการตรวจสอบ)")]):_vm._e(),(item.confirm_pay === 'C')?_c('span',{staticStyle:{"color":"rgb(222, 66, 62)"}},[_vm._v("(ยกเลิก)")]):_vm._e()]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }